/*

  Theme: IADE ES theme

  -----------------------------------
  🚫 DO NOT EDIT THIS FILE DIRECTLY 🚫
  -----------------------------------

  To generate this file:
  1 - Edit `src/themes/theme` files
  2 - Run `yarn build:themes`

*/

[data-theme="iade-es-theme"] {
	/* fontFamily */
	--font-primary: "Poppins", Adjusted Trebuchet MS Fallback;
	--font-secondary: "Poppins", Adjusted Trebuchet MS Fallback;
	/* containers */
	--mobile-max-width: 100%;
	--tablet-max-width: 720px;
	--tablet-lds-max-width: 928px;
	--desktop-max-width: 1200px;
	--big-screen-max-width: 1488px;
	/* brandColors */
	--brand1: #243135;
	--brand2: #ff0988;
	--brand3: #00a099;
	--brand4: #efead5;
	--brand5: #ffffff;
	/* background */
	--ui01: #ffffff;
	--ui02: #efead5;
	--ui03: #ff0988;
	--ui04: #ff0988;
	--ui05: #243135;
	--ui06: #999999;
	--ui07: #d0d0d0;
	--ui08: #ff0988;
	--ui09: #ff0988;
	--ui10: #f6b6d7;
	--ui11: #e6e6e6;
	--ui12: #ffffff00;
	--ui13: #f6f3e7;
	--ui14: #efead5;
	--ui15: #efead5;
	--ui16: #00a099;
	--ui17: #ededed;
	--bg-disabled: #d6d6d6;
	/* backgroundButton */
	--interactive01: #00a099;
	--interactive01Hover: #00bdb5;
	--interactive01Active: #00a099;
	--interactive01Focus: #00a099;
	--interactive01Disabled: #d6d6d6;
	--interactive02: #efead5;
	--interactive02Hover: #f6f3e7;
	--interactive02Active: #ffffff;
	--interactive02Focus: #efead5;
	--interactive02Disabled: #d6d6d6;
	--interactive03: #ffffff;
	--interactive03Hover: #f6f3e7;
	--interactive03Active: #efead5;
	--interactive03Focus: #ffffff;
	--interactive03Disabled: #d6d6d6;
	--interactive04: #ffffff;
	--interactive04Hover: #00a099;
	--interactive04Active: #00a099;
	--interactive04Focus: #00a099;
	--interactive04Disabled: #d6d6d6;
	--interactive05: #24313500;
	--interactive05Hover: #f6b6d7;
	--interactive05Active: #24313500;
	--interactive05Focus: #24313500;
	--interactive05Disabled: #d6d6d6;
	/* borderButton */
	--borderOnInteractive01: #24313500;
	--borderOnInteractive01Hover: #24313500;
	--borderOnInteractive01Active: #24313500;
	--borderOnInteractive01Focus: #ff0988;
	--borderOnInteractive01Disabled: #a3a3a3;
	--borderOnInteractive02: #243135;
	--borderOnInteractive02Hover: #243135;
	--borderOnInteractive02Active: #243135;
	--borderOnInteractive02Focus: #ff0988;
	--borderOnInteractive02Disabled: #a3a3a3;
	--borderOnInteractive03: #224433;
	--borderOnInteractive03Hover: #224433;
	--borderOnInteractive03Active: #224433;
	--borderOnInteractive03Focus: #ff0988;
	--borderOnInteractive03Disabled: #a3a3a3;
	--borderOnInteractive04: #00a099;
	--borderOnInteractive04Hover: #00a099;
	--borderOnInteractive04Active: #00a099;
	--borderOnInteractive04Focus: #ff0988;
	--borderOnInteractive04Disabled: #a3a3a3;
	--borderOnInteractive05: #ff0988;
	--borderOnInteractive05Hover: #ff0988;
	--borderOnInteractive05Active: #ff0988;
	--borderOnInteractive05Focus: #ff0988;
	--borderOnInteractive05Disabled: #a3a3a3;
	/* textButton */
	--textOnInteractive01: #ffffff;
	--textOnInteractive01Hover: #ffffff;
	--textOnInteractive01Active: #ffffff;
	--textOnInteractive01Focus: #ffffff;
	--textOnInteractive01Disabled: #a3a3a3;
	--textOnInteractive02: #243135;
	--textOnInteractive02Hover: #243135;
	--textOnInteractive02Active: #243135;
	--textOnInteractive02Focus: #243135;
	--textOnInteractive02Disabled: #a3a3a3;
	--textOnInteractive03: #243135;
	--textOnInteractive03Hover: #243135;
	--textOnInteractive03Active: #243135;
	--textOnInteractive03Focus: #243135;
	--textOnInteractive03Disabled: #a3a3a3;
	--textOnInteractive04: #00a099;
	--textOnInteractive04Hover: #ffffff;
	--textOnInteractive04Active: #ffffff;
	--textOnInteractive04Focus: #ffffff;
	--textOnInteractive04Disabled: #a3a3a3;
	--textOnInteractive05: #ff0988;
	--textOnInteractive05Hover: #ff0988;
	--textOnInteractive05Active: #ff0988;
	--textOnInteractive05Focus: #ff0988;
	--textOnInteractive05Disabled: #a3a3a3;
	/* textLink */
	--link01: #ff0988;
	--link01Hover: #ff4da9;
	--link01Active: #ff0988;
	--link01Focus: #ff0988;
	/* text */
	--text01: #243135;
	--text02: #6e6e6e;
	--text03: #a3a3a3;
	--text04: #ff0988;
	--text05: #ffffff;
	--text06: #243135;
	--text07: #243135;
	--text08: #ffffff;
	--textAccent: #ff0988;
	--textRegular: #243135;
	--textInverse: #ffffff;
	/* icon */
	--icon01: #243135;
	--icon02: #6e6e6e;
	--icon03: #a3a3a3;
	--icon04: #ff0988;
	--icon05: #ffffff;
	--icon06: #ff0988;
	--icon07: #ff0988;
	--icon08: #ffffff;
	/* border */
	--border01: #243135;
	--border02: #6e6e6e;
	--border03: #a3a3a3;
	--border04: #d6d6d6;
	--border05: #ffffff;
	--border06: #efead5;
	--border07: #d6d6d6;
	--border08: #ff0988;
	--border09: #ff0988;
	/* gradients */
	--gradient-bg: linear-gradient(
		180deg,
		rgba(36, 49, 53, 0) 0%,
		rgba(36, 49, 53, 1) 75%
	);
	--gradient01: linear-gradient(270deg, transparent 1%, #fff);
	/* support */
	--facebook: #3b5998;
	--twitter: #00acee;
	--whatsApp: #00bb2d;
	--linkedIn: #0e76a8;
	--success: #96d621;
	--error: #de2b31;
	--errorInverse: #d9d9d9;
	/* lightbox */
	--lightboxBg: #ffffff;
	--lightboxBorder: #ededed;
	/* dots */
	--dot: #d6d6d6;
	--dot-active: #a3a3a3ff;
	/* spacing */
	--spc4: 4px;
	--spc8: 8px;
	--spc16: 16px;
	--spc24: 24px;
	--spc32: 32px;
	--spc48: 48px;
	--spc64: 64px;
	--spc96: 96px;
	--spc128: 120px;
	--spc256: 256px;
	--spc384: 384px;
	--spc512: 512px;
	--spc640: 640px;
	--spc768: 768px;
	/* scaleSpacing */
	--outSpc20: 20px;
	--outSpc40: 40px;
	--outSpc56: 56px;
	--outSpc72: 72px;
	--outSpc80: 80px;
	--outSpc144: 144px;
	--outSpc176: 176px;
	--outSpc344: 344px;
	/* z-index */
	--zIndexInitial: initial;
	--zIndexNegative10: -10;
	--zIndexNegative: -1;
	--zIndex0: 0;
	--zIndex1: 1;
	--zIndex2: 2;
	--zIndex3: 3;
	--zIndex10: 10;
	--zIndex20: 20;
	--zIndex50: 50;
	--zIndex100: 100;
	--zIndex999: 999;
	--zIndex1000: 1000;
	--zIndex1001: 1001;
	--zIndex1002: 1002;
	/* radii */
	--radii-none: 0px;
	--radii-xs: 4px;
	--radii-sm: 8px;
	--radii-md: 16px;
	--radii-lg: 24px;
	--radii-xl: 32px;
	--radii-full: 9999px;
	/* sizes */
	--size-2xs: 8px;
	--size-xs: 16px;
	--size-sm: 24px;
	--size-md: 32px;
	--size-lg: 48px;
	--size-xl: 56px;
	--size-2xl: 64px;
	/* borderWidth */
	--border-width-none: 0px;
	--border-width-xs: 1px;
	--border-width-sm: 2px;
	--border-width-md: 4px;
	--border-width-lg: 8px;
	/* animations */
	--fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1);
	--show-up: show-up 2s ease-in-out;
	--delay-fade-in: fadein both 1.5s cubic-bezier(0.61, 0.43, 0.15, 1) 0.1s;
	--menu-hover: menu-hover both 0.3s cubic-bezier(0.61, 0.43, 0.15, 1);
	--in-expo: cubic-bezier(0.61, 0.43, 0.15, 1);
	/* timing */
	--duration-xs: 0.1s;
	--duration-sm: 0.2s;
	--duration-md: 0.3s;
	--duration-lg: 0.5s;
	--duration-xl: 0.6s;
	/* easing */
	--easing-xs: var(--ease-in-out-quad);
	--easing-xs: var(--ease-in-out-quad);
	--easing-sm: var(--ease-in-out-cubic);
	--easing-md: var(--ease-in-out-quart);
	--easing-lg: var(--ease-in-out-quint);
	--easing-xl: var(--ease-in-out-expo);
	/* easingFunctions */
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
}
[data-theme="iade-es-theme"][data-subtheme="defaultAlt"],
[data-theme="iade-es-theme"] [data-subtheme="defaultAlt"] {
	/* background */
	--ui01: #efead5;
	--ui02: #ffffff;
	--ui06: #a3a3a3;
	--ui07: #d6d6d6;
	--ui11: #ff0988;
	--ui12: #efead5;
	--ui14: #ffffff;
	--ui15: #ffffff;
	/* border */
	--border06: #ffffff;
	/* icon */
	--icon07: #243135;
}
[data-theme="iade-es-theme"][data-subtheme="accent"],
[data-theme="iade-es-theme"] [data-subtheme="accent"] {
	/* background */
	--ui01: #ff0988;
	--ui02: #efead5;
	--ui05: #ffffff;
	--ui07: #d6d6d6;
	--ui08: #ffffff;
	--ui14: #ffffff;
	--ui15: #ffffff;
	--bg-disabled: #d6d6d6;
	/* textLink */
	--link01: #efead5;
	--link01Hover: #f6f3e7;
	--link01Active: #efead5;
	--link01Focus: #efead5;
	/* text */
	--text01: #ffffff;
	--text04: #ffffff;
	--text05: #243135;
	--text06: #243135;
	/* icon */
	--icon01: #ffffff;
	--icon04: #ff0988;
	--icon05: #243135;
	--icon06: #ffffff;
	--icon07: #243135;
	/* border */
	--border01: #ffffff;
	--border04: #ffffff;
	--border05: #ffffff;
	--border08: #ece7cf;
}
[data-theme="iade-es-theme"][data-subtheme="accentAlt"],
[data-theme="iade-es-theme"] [data-subtheme="accentAlt"] {
	/* background */
	--ui01: #00a099;
	--ui02: #efead5;
	--ui05: #ffffff;
	--ui06: #999999;
	--ui08: #ffffff;
	--ui14: #ffffff;
	--ui15: #ffffff;
	/* textLink */
	--link01: #efead5;
	--link01Hover: #f6f3e7;
	--link01Active: #efead5;
	--link01Focus: #efead5;
	/* text */
	--text01: #ffffff;
	--text04: #ffffff;
	--text05: #243135;
	--text06: #243135;
	/* icon */
	--icon01: #ffffff;
	--icon04: #ff0988;
	--icon05: #243135;
	--icon06: #ffffff;
	--icon07: #243135;
	/* border */
	--border01: #ffffff;
	--border04: #ffffff;
	--border05: #ffffff;
	--border09: #e6e6e6;
}
[data-theme="iade-es-theme"][data-subtheme="accentLight"],
[data-theme="iade-es-theme"] [data-subtheme="accentLight"] {
	/* background */
	--ui01: #efead5;
	--ui15: #ffffff;
	/* textLink */
	--link01: #243135;
	--link01Hover: #f6f3e7;
	--link01Active: #efead5;
	--link01Focus: #efead5;
}
