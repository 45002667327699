.display01,
.display02,
.title01,
.title02,
.title03,
.title04,
.title05,
.title06,
.title01Bold,
.title02Bold,
.title05Bold,
.subtitle01,
.subtitleL,
.subtitleLLink,
.subtitleM,
.subtitleS,
.subtitle01Bold,
.subtitleLBold,
.subtitleMBold,
.subtitleMBold,
.subtitleSBold,
.quote01,
.lead,
.leadLink,
.leadBold,
.body,
.bodyBold,
.bodyHeader,
.bodyCard,
.bodyTight,
.bodyTightBold,
.bodyLink,
.bodyTightLink,
.bodyButton,
.bodyButtonLink,
.link,
.small,
.smallTight,
.smallBold,
.smallTightBold,
.smallLink,
.smallTightLink,
.tiny,
.tinyTight,
.tinyBold,
.tinyTightBold,
.tinyLink,
.tinyTightLink,
.tinyTightSerif {
	font-family: var(--font-primary);
}

.textLight {
	font-weight: 200 !important;
}

.display01,
.display02,
.title01,
.title02,
.title03,
.title04,
.title05,
.title06,
.subtitle01,
.subtitleL,
.subtitleLLink,
.subtitleM,
.subtitleS,
.quote01,
.lead,
.leadLink,
.body,
.bodyHeader,
.bodyCard,
.bodyTight,
.bodyLink,
.bodyTightLink,
.bodyButton,
.bodyButtonLink,
.link,
.small,
.smallTight,
.smallLink,
.smallTightLink,
.tiny,
.tinyTight,
.tinyLink,
.tinyTightLink,
.tinyTightSerif {
	font-weight: 400;
}

.title01Bold,
.title02Bold,
.title05Bold,
.subtitle01Bold,
.subtitleLBold,
.subtitleMBold,
.subtitleMBold,
.subtitleSBold,
.leadBold,
.bodyBold,
.bodyTightBold,
.smallBold,
.smallTightBold,
.tinyBold,
.tinyTightBold {
	font-weight: 600;
}

.display01 {
	font-size: 34px;
	line-height: 1.2;
}

.display02 {
	font-size: 32px;
	line-height: 1.2;
}

.title01,
.title01Bold {
	font-size: 28px;
	line-height: 1.2;
}

.title02,
.title02Bold {
	font-size: 26px;
	line-height: 1.2;
}

.title03 {
	font-size: 26px;
	line-height: 1.2;
}

.title04 {
	font-size: 23px;
	line-height: 1.2;
}

.title05,
.title05Bold {
	font-size: 22px;
	line-height: 1.2;
}

.title06 {
	font-size: 18px;
	line-height: 1.2;
}

/* TODO: ¿Delete? */
.subtitle01,
.subtitle01Bold {
	font-size: 15px;
	line-height: 1.2;
}

.subtitle01Bold {
	font-size: 15px;
	line-height: 1.2;
}

.subtitleL,
.subtitleLLink,
.subtitleLBold {
	font-size: 20px;
	line-height: 1.5;
}

.subtitleM,
.subtitleMBold {
	font-size: 18px;
	line-height: 1.5;
}

.subtitleS,
.subtitleSBold {
	font-size: 16px;
	line-height: 1.5;
}

.quote01 {
	font-size: 15px;
	line-height: 1.2;
}

.lead,
.leadLink,
.leadBold {
	font-size: 18px;
	line-height: 1.2;
}

.body,
.bodyLink,
.bodyButton,
.bodyHeader {
	font-size: 14px;
	line-height: 1.5;
}

.bodyTight,
.bodyTightLink,
.bodyTightBold {
	font-size: 14px;
	line-height: 1.2;
}

.bodyButtonLink {
	font-size: 15px;
	line-height: 1.5;
}

.link {
	font-size: 15px;
	line-height: 1.2;
}

.bodyCard {
	font-size: 15px;
	line-height: 1.2;
}

.small,
.smallLink {
	font-size: 13px;
	line-height: 1.5;
}

.smallTight,
.smallTightLink {
	font-size: 13px;
	line-height: 1.2;
}

.tiny,
.tinyLink {
	font-size: 12px;
	line-height: 1.5;
}

.tinyTight,
.tinyTightLink,
.tinyTightSerif {
	font-size: 12px;
	line-height: 1.2;
}

@media (--tablet) {
	.display01 {
		font-size: 56px;
	}

	.display02 {
		font-size: 48px;
	}

	.title01,
	.title01Bold {
		font-size: 40px;
	}

	.title02,
	.title02Bold {
		font-size: 36px;
	}

	.title03 {
		font-size: 32px;
	}

	.title04 {
		font-size: 26px;
	}

	.title05,
	.title05Bold {
		font-size: 22px;
	}

	.title06 {
		font-size: 20px;
	}

	.subtitle01,
	.subtitle01Bold,
	.subtitle01Link {
		font-size: 17px;
	}

	.quote01 {
		font-size: 17px;
	}
}

@media (--desktop) {
	.display01 {
		font-size: 72px;
	}

	.display02 {
		font-size: 60px;
	}

	.title01,
	.title01Bold {
		font-size: 52px;
	}

	.title02,
	.title02Bold {
		font-size: 48px;
	}

	.title03 {
		font-size: 40px;
	}

	.title04 {
		font-size: 32px;
	}

	.title05,
	.title05Bold {
		font-size: 24px;
	}

	.subtitle01,
	.subtitle01Bold,
	.subtitle01Link {
		font-size: 18px;
	}

	.quote01 {
		font-size: 18px;
	}

	.bodyCard {
		font-size: 16px;
	}
}

@media (--big-screen) {
	.display01 {
		font-size: 88px;
	}

	.display02 {
		font-size: 76px;
	}

	.title01,
	.title01Bold {
		font-size: 60px;
	}

	.title02,
	.title02Bold {
		font-size: 56px;
	}

	.title03 {
		font-size: 48px;
	}

	.title04 {
		font-size: 40px;
	}

	.title05,
	.title05Bold {
		font-size: 32px;
	}

	.title06 {
		font-size: 22px;
	}

	.subtitle01,
	.subtitle01Bold {
		font-size: 22px;
	}

	.subtitle01Link {
		font-size: 22px;
	}

	.subtitleL,
	.subtitleLBold,
	.subtitleLLink {
		font-size: 22px;
	}

	.subtitleM,
	.subtitleMBold {
		font-size: 20px;
	}

	.subtitleM,
	.subtitleMBold {
		font-size: 18px;
	}

	.quote01 {
		font-size: 22px;
	}

	.lead,
	.leadBold,
	.leadLink {
		font-size: 22px;
	}

	.body,
	.bodyBold,
	.bodyHeader,
	.bodyCard,
	.bodyTight,
	.bodyTightBold,
	.bodyLink,
	.bodyTightLink,
	.bodyButton {
		font-size: 15px;
	}

	.small,
	.smallTight,
	.smallBold,
	.smallTightBold,
	.smallLink,
	.smallTightLink {
		font-size: 14px;
	}

	.tiny,
	.tinyTight,
	.tinyBold,
	.tinyTightBold,
	.tinyLink,
	.tinyTightLink,
	.tinyTightSerif {
		font-size: 13px;
	}
}
